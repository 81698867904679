$gray:    #282b30;
$gray2:   #495057;
$gray3:   #6c757d;
$gray4:   #dee2e6;
$gray5:   #999;
$gray6:   #f2f2f2;

$blue:    #2196f3;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #ff0066;
$red:     #dc3545;
$red2:     #721c24;
$orange:  #ff9800 ;
$orange2:  #f97b3e;
$yellow:  #FBC02D;
$green:   #28a745;
$teal:    #03A473;
$cyan:    #17a2b8;
$cyan2:    #30cbb0;


$primary:       $teal;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;

// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1260px
// );

*:root {
  --menu-backround-color: rgba(128, 0, 128, 1);
  --link-active-color: #fedc43;
  --gray-background: #f8f9fa;
  --gray-text: rgb(136,136,136);
  --not-paid-color: red;
  --paid-color: #3acd3a;
}
