.p-button {
  background-color: transparent;
  color: black;
  border: 1px solid #cccccc;
}

.p-button:enabled:hover {
  background-color: #f4f4f4;
  color: black;
  border: 1px solid #cccccc;
}

.p-component {

}
